import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import DieselTracksVideoBlock from '../components/diesel-tracks-video-block'
import DieselTracksLineUp from '../components/diesel-tracks-line-up'
import DieselTracksStyles from '../css/pages/diesel-tracks-styles'
import ImageCarouselStyles from '../css/components/image-carousel'
import ImageCarousel from '../components/image-carousel'
import DieselTracksTextBlock from '../components/diesel-tracks-text-block'

const DieselTracksCollectionTemplate = ({ data }) => {
  const article = data.strapiDieselTracksCollection

  function renderVideoBlock(section) {
    return (
      <section
        className="diesel-tracks__section diesel-tracks__section--video-block"
        key={`${section.strapi_component}-${section.id}`}>
        <DieselTracksVideoBlock section={section} />
      </section>
    )
  }

  function renderVideoBlock16x9(section) {
    return (
      <section
        className="diesel-tracks__section diesel-tracks__section--video-block-16-9"
        style={{ position: 'relative' }}
        key={`${section.strapi_component}-${section.id}`}>
        <DieselTracksVideoBlock section={section} />
      </section>
    )
  }

  function renderImageCarousel(section) {
    if (!section.images.length) return

    return (
      <section
        className="diesel-tracks__section diesel-tracks__section--image-carousel"
        key={`${section.strapi_component}-${section.id}`}>
        <ImageCarousel section={section} />
      </section>
    )
  }

  return (
    <Layout>
      <link rel="stylesheet" href="https://use.typekit.net/jnb8zaq.css" />
      <style>
        {DieselTracksStyles}
        {ImageCarouselStyles}
      </style>

      <main className="diesel-tracks diesel-tracks--collection">
        {article.sections.map((section) => {
          switch(section.strapi_component) {
          case 'diesel-tracks.diesel-tracks-video-block':
            return renderVideoBlock(section)
          case 'diesel-tracks.line-up':
            return <DieselTracksLineUp section={section} key={section.strapi_component} />
          case 'components.text-block':
            return <DieselTracksTextBlock section={section} key={section.strapi_component} />
          case 'components.image-carousel':
            return renderImageCarousel(section)
          case 'diesel-tracks.video-block-16-9':
            return renderVideoBlock16x9(section)
          default:
            return null
          }
        })}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query DieselTracksArticleQuery($slug: String!) {
    strapiDieselTracksCollection(slug: { eq: $slug }) {
      strapiId
      sections
    }
  }
`

DieselTracksCollectionTemplate.propTypes = {
  data: PropTypes.object,
}

export default DieselTracksCollectionTemplate
